// libraries
import React, { useMemo, useState } from 'react'
import { prismicPropTypes } from 'helpers/proptypes'
import { useTranslation } from 'next-i18next'
import { fetchPrismicHomePage } from 'services/prismic'
import { serverSideTranslations } from 'next-i18next/serverSideTranslations'
// components
import NextHead from 'next/head'
import { DefaultLayout } from 'layouts/DefaultLayout'
import { Header } from 'layouts/Header'
import { Footer } from 'layouts/Footer'
import { HeroSection } from 'components/shared/sections'
import { Lazy } from '@vizeat/components/es6/components/Lazy'
import { Box } from '@vizeat/components/es6/components/Box'
// helpers
import { mainImagesMeta } from 'helpers/ImageManager'
import { urlFactory } from 'helpers/url'
import { COOKIE_KEYS, loadCookie } from 'helpers/cookies'
import { cacheRules } from 'helpers/cache'
import { initApplication } from 'helpers/SSR'
// gtm
import {
  AboutSection,
  HomeSearchForm,
  MobileHeader,
  TopDestinationsSection,
  PressSection,
  SeparatorCurve,
  HomeEventsSearchSection,
} from 'components/pages/home'
import { PopularDestinationsSection } from 'components/shared/prismic'
import { GTM_EVENT_TYPES } from 'gtm'

const MAX_EVENTS = 8

const lastViewedEventsIds = loadCookie(COOKIE_KEYS.LAST_VIEWED_EXPERIENCES)?.split(',') || []

const lastViewedEventsPayload = {
  includeEventIds: lastViewedEventsIds.map(Number),
}

const eventsNearMePayload = {
  online: false,
  size: MAX_EVENTS,
  useIPGeoLocation: true,
}

export const getServerSideProps = async ({ locale, previewData, query, res, req }) => {
  const cookieCurrency = req.cookies[COOKIE_KEYS.EW_CURRENCY]
  initApplication({ locale, currency: query.c || cookieCurrency })

  res.setHeader('Cache-Control', cacheRules.home)

  return {
    props: {
      data: await fetchPrismicHomePage({ locale, previewData }),
      ...(await serverSideTranslations(locale || 'en')),
    },
  }
}

export default function Home({ data }) {
  const { t } = useTranslation()
  const [showHeaderSearch, setShowHeaderSearch] = useState(false)

  const playlistDateFilters = useMemo(
    () => data.find((item) => item.slice_type === 'playlists__date_range_filters')?.items,
    [data],
  )

  const playlistMealTypeFilters = useMemo(
    () => data.find((item) => item.slice_type === 'playlists__experience_type_filters')?.items,
    [data],
  )

  if (!data || data.length === 0) return null

  return (
    <DefaultLayout bg='transparent' title={t('Meta::Title::Home::VizEat: Immersive Food Experiences')}>
      <NextHead>
        <meta
          key='description'
          name='description'
          property='og:description'
          content={t(
            'Meta::Description::Home::Book immersive food events and meal sharing experiences at one of our local hosts’ in more than 110 countries. Taste the city with locals.',
          )}
        />

        <meta key='title' property='og:title' content={t('Meta::Title::Home::VizEat: Immersive Food Experiences')} />
        {mainImagesMeta().map((props, key) => (
          <meta key={key} {...props} />
        ))}

        {/* prefer english url as canonical one for social shares */}
        <meta key='url' property='og:url' content={urlFactory.web.home('en')} />
      </NextHead>
      <Box display={{ default: 'none', tablet: 'block' }}>
        <Header isTransparent />
      </Box>
      <Box display={{ default: 'block', tablet: 'none' }} height='70px'>
        <MobileHeader onShowSearchChange={setShowHeaderSearch} shouldShowSearch={showHeaderSearch} />
      </Box>

      <>
        {data.map((section) => {
          switch (section.slice_type) {
            case 'hero_section':
              return (
                <HeroSection
                  key={section.slice_type}
                  backgroundImage={section.primary.background_image}
                  smart={section.primary.smartly_crop_background === 'true'}
                  title={section.primary.title}
                  subtitle={section.primary.text}
                  alt={section.primary.alt || ''}
                  minHeight={{ default: 538, desktop: 596 }}
                >
                  <HomeSearchForm isMobileHeaderSearchVisible={showHeaderSearch} />
                </HeroSection>
              )
            case 'about':
              return (
                <Lazy key={section.id}>
                  <AboutSection title={section.primary.section_title} items={section.items} />
                </Lazy>
              )
            case 'separator_curve':
              return section.primary.should_show_separator && <SeparatorCurve key={section.id} />
            case 'playlist_section':
              return (
                <Lazy key={section.id}>
                  <TopDestinationsSection
                    items={section.items}
                    title={section.primary.playlist_selection_title}
                    dateRangeFilters={section.primary.should_display_filters ? playlistDateFilters : undefined}
                    mealtypeFilters={
                      section.primary.should_display_experience_type ? playlistMealTypeFilters : undefined
                    }
                  />
                </Lazy>
              )
            case 'favorite_experiences_section':
              return (
                lastViewedEventsPayload.includeEventIds.length > 0 && (
                  <Lazy key={section.id}>
                    <HomeEventsSearchSection
                      showWrapper
                      title={section.primary.title}
                      gtmType={GTM_EVENT_TYPES.HOME_LAST_VIEWED}
                      searchPayload={lastViewedEventsPayload}
                      variant='small'
                    />
                  </Lazy>
                )
              )
            case 'near_you_section':
              return (
                <Lazy key={section.id}>
                  <HomeEventsSearchSection
                    title={section.primary.title}
                    gtmType={`${GTM_EVENT_TYPES.HOME_IN_PERSON_EXPERIENCES}@@${GTM_EVENT_TYPES.HOME_USER_IP_COUNTRY}`}
                    searchPayload={eventsNearMePayload}
                    variant='small'
                  />
                </Lazy>
              )
            case 'popular_destinations_section':
              return (
                <Lazy key={section.id}>
                  <PopularDestinationsSection staticFields={section.primary} items={section.items} />
                </Lazy>
              )
            case 'press_section':
              return (
                <Lazy key={section.id}>
                  <PressSection items={section.items} />
                </Lazy>
              )
            default:
              return null
          }
        })}
      </>
      <Footer />
    </DefaultLayout>
  )
}

Home.propTypes = prismicPropTypes
